import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=292dfab1"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BottomSeo: require('/app/components/BottomSeo.vue').default,IconRTA: require('/app/components/icons/IconRTA.vue').default,IconGrayLogo: require('/app/components/icons/IconGrayLogo.vue').default,Footer: require('/app/components/Footer.vue').default})
